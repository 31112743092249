import React from 'react';
import Image from '../images/image.jsx';
import util from '../../utilities';


class Lightbox extends React.Component {

    constructor() {
        super();
        this.handleClose = this.handleClose.bind(this);
        this.handleNavigate = this.handleNavigate.bind(this);
        this.handleKeydown = this.handleKeydown.bind(this);
        this.handleResize = this.handleResize.bind(this);

        this.state = {
            innerWidth: window ? window.innerWidth : 0,
            innerHeight: window ? window.innerHeight : 0
        };
    }

    componentDidMount() {
        this.handleResize();
        if (window) {
            window.addEventListener('resize', this.handleResize);
            window.addEventListener('keydown', this.handleKeydown);
            window.onwheel = this.preventDefault;
            window.ontouchmove = this.preventDefault;
        }
    }

    componentWillUnmount() {
        if (window) {
            window.removeEventListener('resize', this.handleResize);
            window.removeEventListener('keydown', this.handleKeydown);
            window.onwheel = null;
            window.ontouchmove = null;
        }
    }

    preventDefault(e) {
        e = e || window.event;
        if (e.preventDefault) {
            e.preventDefault();
        }
        e.returnValue = false;
    }

    handleResize() {
        if (window) {
            const {innerWidth, innerHeight} = window;
            this.setState({innerWidth, innerHeight});
        }
    }

    handleClose() {
        this.props.onHandleClick(false);
    }

    handleKeydown(e) {
        if (e.keyCode === 27) {
            this.handleClose();
        } else if (e.keyCode === 37 || e.keyCode === 39) {
            const direction = e.keyCode - 38;
            this.handleNavigate(direction);
        }
    }

    handleNavigate(direction) {

        const currentIndex = this.props.images
            .findIndex((image) => image.ID === this.props.currentIndex);

        let nextIndex = currentIndex + direction;
        if (nextIndex > this.props.images.length - 1) {
            nextIndex = 0;
        } else if (nextIndex < 0) {
            nextIndex = this.props.images.length - 1;
        }

        const nextImage = this.props.images[nextIndex].ID;
        this.props.onHandleClick(nextImage);
    }

    render() {

        const currentImage = this.props.images
            .filter((image) => image.ID === this.props.currentIndex)[0];

        if (currentImage) {

            let src;
            if (currentImage.sizes) {
                src = currentImage.sizes['custom-extra-large'] ||
                      currentImage.sizes['custom-large'] ||
                      currentImage.url;
            } else {
                src = currentImage.url;
            }

            let style = {
                width: 'auto',
                height: 'auto',
                maxWidth: this.state.innerWidth,
                maxHeight: this.state.innerHeight
            };

            if (currentImage.width > currentImage.height &&
                this.state.innerWidth > currentImage.width) {
                style.width = this.state.innerWidth;
            }

            if (currentImage.height > currentImage.width &&
                this.state.innerHeight > currentImage.height) {
                style.width = '100%';
            }

            return (
                <div className='z3 fixed top-0 bottom-0 left-0 right-0 bg-black'>
                    <div className='relative height-100'>
                        <div
                            onClick={this.handleClose}
                            className='z3 absolute right icon-cross cursor-pointer white p2' />
                        <div className='absolute top-0 bottom-0 left-0 right-0'>
                            <div className='table height-100 col-12'>
                                <div className='table-cell center align-middle height-100 relative'>
                                    <div className='relative'>
                                        <Image
                                            style={style}
                                            className='px4 sm-px6 block'
                                            image={src} />
                                        <div
                                            style={{width: this.state.innerWidth}}
                                            className="absolute bottom-0 left-0 right-0 px4 sm-px6 bg-half-black">
                                            <div
                                                className="p2 white left-align h3 caps"
                                                dangerouslySetInnerHTML={{__html: currentImage.caption}} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="height-100">
                        <div className="v-centre left-0 right-0">
                            <div
                                onClick={this.handleNavigate.bind(this, -1)}
                                className='left cursor-pointer px1 sm-px2 h1 white'>
                                +
                            </div>
                            <div
                                onClick={this.handleNavigate.bind(this, 1)}
                                className='right cursor-pointer px1 sm-px2 h1 white'>
                                +
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return null;
        }

    }
}

module.exports = Lightbox;
