import React from 'react';
import Image from '../images/image.jsx';
import util from '../../utilities';


/**
 * class representing a lightbox item, callsback with `image.ID` on click
 *
 * @extends React.Component
 * required @prop {function} onHandleClick - callback with the clicked image ID
 */
class LightboxItem extends React.Component {

    constructor() {
        super();
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick() {
        if (this.props.onHandleClick) {
            this.props.onHandleClick(this.props.image.ID);
        }
    }

    render() {
        return (
            <div
                onClick={this.handleClick}
                className='cursor-pointer'>
                <Image
                    className={this.props.className}
                    image={this.props.image} />
            </div>
        );
    }
}

module.exports = LightboxItem;
