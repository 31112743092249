import React from 'react';
import AjaxListing from '../base/ajax-listing.jsx';
import ListingItem from './item.jsx';


class Listing extends AjaxListing {

    render() {

        return (
            <div>
                {this.state.items.map((item, i) => (
                    <ListingItem
                        key={item.id}
                        item={item} />
                ))}
            </div>
        );
    }
}

module.exports = Listing;
