/*
 *  Images
 */

import React from 'react';
import util from '../../utilities';


/**
 * Fallback image component
 * @prop {string} className - additional CSS classes
 */
class Default extends React.Component {

    render() {
        const style = this.props.style || {width:'100%',height:'350px'};
        return (
            <img
                className={this.props.className}
                style={style}
                src='data:image/gif;base64,R0lGODlhAQABAIAAAMLCwgAAACH5BAAAAAAALAAAAAABAAEAAAICRAEAOw=='
                alt='no image' />
        );
    }

};

/**
 * Class representing an image
 * With loader and support for responsive images
 * @prop {object} image - a WordPress image object
 * @prop {string} className - additional CSS classes
 * @prop {string} alt - alt text for the image
 * @prop {number} width - percentage base width
 * @prop {number} smWidth - percentage sm breakpoint width
 * @prop {number} mdWidth - percentage md breakpoint width
 * @prop {number} lgWidth - percentage lg breakpoint width
 * @prop {string} aspect - use one of the cropped aspect ratios from WordPress
 * @prop {boolean} loader
 */
class Image extends React.Component {

    // update state when the image is loaded
    constructor(props) {
        super(props);
        this.state = {
            loaded: false
        };
    }

    componentDidMount() {
        if (this.refs.image) {
            var imageNode = this.refs.image;
            imageNode.src = imageNode.currentSrc || this.props.image.url;
            if (imageNode.complete) {
                this.handleOnLoad.call(this);
            } else {
                imageNode.onload = this.handleOnLoad.bind(this);
            }
        }
    }

    // fade in
    componentDidUpdate(prevProps, prevState) {
        if (this.state.loaded && !prevState.loaded) {
            window.requestAnimationFrame(() => {
                if (this.refs.image) {
                    this.refs.image.style.opacity = 1;
                }
            });
        }
    }

    // remove the loaded listener if the component will unmount
    componentWillUnmount() {
        if (this.refs.image && this.state.loaded === false) {
            var imageNode = this.refs.image;
            imageNode.src = imageNode.currentSrc || this.props.image.url;
            imageNode.onload = undefined;
        }
    }

    // handle load
    handleOnLoad() {
        if (this.props.hasLoaded) {
            this.props.hasLoaded();
        }

        this.setState({
            loaded: true
        });
    }

    // render image
    render() {

        let classes = this.props.className || '';
        classes += ' transition-opacity';

        let style = this.props.style
            ? {...this.props.style}
            : {};

        style.opacity = 0.001;

        let image;

        if (this.props.image) {
            // Don't build a srcset if the image is an svg
            if (util.try(() => this.props.image.mime_type) &&
                util.try(() => tthis.props.image.mime_type.match('svg')) ||
                util.try(() => tthis.props.image.mime_type.match('gif'))) {

                return (
                    <img
                        ref='image'
                        className={' ' + classes}
                        style={style}
                        src={this.props.image.url}
                        alt={this.props.alt} />
                );

            // Don't build a source set if the image is specified as a path or url
            } else if (typeof this.props.image === 'string') {

                image = (
                    <img
                        ref='image'
                        className={' ' + classes}
                        style={style}
                        srcSet={this.props.image}
                        alt={this.props.alt} />
                );

            } else if (typeof this.props.image.sizes === 'object') {

                let sm, md, lg, exlg;
                let aspect = this.props.aspect ? this.props.aspect + '-' : '';
                if (this.props.image.sizes['custom-' + aspect + 'small']) {
                    sm = this.props.image.sizes['custom-' + aspect + 'small'];
                    md = this.props.image.sizes['custom-' + aspect + 'medium'] || sm;
                    lg = this.props.image.sizes['custom-' + aspect + 'large'] || md;
                    exlg = this.props.image.sizes['custom-' + aspect + 'extra-large'] || lg;
                } else {
                    sm = this.props.image.sizes['custom-small'] || this.props.image.url;
                    md = this.props.image.sizes['custom-medium'] || sm;
                    lg = this.props.image.sizes['custom-large'] || md;
                    exlg = this.props.image.sizes['custom-extra-large'] || lg;
                }

                let sizes = '';
                sizes += (this.props.lgWidth) ? '(min-width: 1440px) ' + this.props.lgWidth + 'vw, ' : '';
                sizes += (this.props.mdWidth) ? '(min-width: 1020px) ' + this.props.mdWidth + 'vw, ' : '';
                sizes += (this.props.smWidth) ? '(min-width: 760px) ' + this.props.smWidth + 'vw, ' : '';
                sizes += (this.props.width) ? this.props.width + 'vw' : '100vw';

                let srcSet = '';
                srcSet += sm + ' 320w, ';
                srcSet += md + ' 720w, ';
                srcSet += lg + ' 1440w, ';
                srcSet += exlg + ' 2880w';

                let classes = this.props.className || '';
                classes += ' transition-opacity';

                image = (
                    <img
                        ref='image'
                        className={' block ' + classes}
                        style={style}
                        sizes={sizes}
                        srcSet={srcSet}
                        alt={this.props.alt} />
                );

            } else {
                image = (
                    <Default className={this.props.className} />
                );
            }
        } else {
            image = (
                <Default className={this.props.className} />
            );
        }

        if (this.props.loader) {

            return (
                <div className='relative'>
                    {/* Loader */}
                    {!this.state.loaded &&
                        <div className='absolute force-v-center black animate-flash'>
                            <i className='icon-circle-fill line-reset large-icon'></i>
                        </div>
                    }
                    {/* Image */}
                    {image}
                </div>
            );

        } else {

            return image;

        }

    }

};

module.exports = Image;
