/*
 * Home listing container
 */

import React from 'react';
import ListingContainer from '../base/infinite-container.jsx';
import Listing from './listing.jsx';
import util from '../../../utilities';


/**
 * Class representing a Journal listing container
 * @extends React.component
 * @prop {array} items
 * @prop {number} pages
 * @prop {boolean} morePages
 */
class HomeListingContainer extends ListingContainer {

    render() {

        return (
            <section className='relative'>

                {/* Listing */}
                <Listing
                    path='/api/journal'
                    items={this.props.items}
                    query={{
                        ...this.props.query,
                        pages: this.state.pages
                    }}
                    morePages={this.state.morePages}
                    onLoad={this.handleLoad} />

            </section>
        );

    }
}

module.exports = HomeListingContainer;
