import React from 'react';

class Share extends React.Component {

    render() {

        const {url, title} = this.props;

        const links = [
            {label: 'Facebook', value: `https://www.facebook.com/sharer/sharer.php?u=${url}`},
            {label: 'Email', value: `mailto:?subject=${title}&body=${url}`},
            {label: 'Twitter', value: `https://twitter.com/home?status=${url}`}
        ].map((link, i) => (
            <li key={i}>
                <a href={link.value}>
                    {link.label}
                </a>
            </li>
        ));

        return (
            <div className="clearfix container">
                <div className="mt8">
                    <div className="col-right sm-col col-11 sm-col-3 md-col-4">
                        <h3 className='h3 caps mt2 mb0 sm-mt0'>Share</h3>
                    </div>
                    <div className="col-right col-11 sm-col-9 md-col-8">
                        <ul className="list-reset h4 bold mt1 sm-mt0">
                            {links}
                        </ul>
                    </div>
                </div>
            </div>
        );
    }
}

module.exports = Share;
