/*
 * Home
 */

import React, {Fragment} from 'react';
import Moment from 'moment';
import Header from '../partials/header.jsx';
import Footer from '../partials/footer.jsx';
import util from '../utilities';
import Image from '../partials/images/image.jsx';
import { XMasonry, XBlock } from "react-xmasonry";

/**
 * Class representing the homepage
 * @extends React.Component
 */
class ProductDrop extends React.Component {
  
    render() {

      const page = this.props.page;
        return (
            <div className='main'>
                <Header
                  url={'/shop'} 
                  pause={util.try(() => this.props.settings.pause)}
                  tags={util.try(() => this.props.settings.tags)} 
                  active={this.props.newsletterOpen ? 'newsletter' : null} />
                <div className='container'>
                                    
                    <div className='mx1 sm-mx3 md-mx4 ml4'>

                      <div className='container relative'>

                        <h1 
                          className='sticky mt8 sticky-title sticky-title-shop top-25 center z1'
                          style={{paddingTop: '4rem'}}>
                          {page.title.rendered}
                        </h1>


                        <XMasonry 
                          targetBlockWidth={120}
                          smartUpdateCeil={1000} 
                          columns={12} 
                          center={false}>                          
                         {page.acf.products && page.acf.products.map((item, i) => {
                          return (
                            <XBlock 
                              key={i} 
                              width={Math.ceil(item.column_width)}>
                              <div 
                                className={`mt2 sm-mx3 mb3 sm-mb5`}
                                key={i}>
                                <a href={item.product[0].acf.product_url} target="_blank">
                                  <div className={Math.ceil(item.column_width) === 3 ? 'col-8' : 'col-12'}>
                                  <Image image={item.product[0].acf.image} className={'col-12'} />
                                  <div className='h3 caps mt1'>
                                    {item.product[0].title}, {item.product[0].acf.price}
                                  </div>
                                  </div>
                                </a>
                              </div>
                            </XBlock>
                          )
                         })}
                         </XMasonry>
                      </div>
                      <div className="container">
                        <div className="sm-flex justify-end">
                          <div className="sm-col-8 py4 sm-py6">
                            <div dangerouslySetInnerHTML={{__html: page.acf.intro}}></div>
                          </div>
                        </div>
                        <div>
                          <a
                            className='h1' 
                            href="/shop-archive">Browse Past</a>
                        </div>
                      </div>
                    </div>
                </div>
                <Footer menu={util.try(() => this.props.settings.footer)} />
            </div>
        );
    }
};

module.exports = ProductDrop;
