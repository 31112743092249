
/*
 * Home
 */

import React, {Fragment} from 'react';
import Header from '../partials/header.jsx';
import Footer from '../partials/footer.jsx';


import Listing from '../partials/listing/home/index.jsx';
import util from '../utilities';
import Features from '../partials/home/features.jsx';


/**
 * Class representing the homepage
 * @extends React.Component
 */
class Home extends React.Component {
  
    render() {

        return (
            <div className='main'>
                <Header 
                  pause={util.try(() => this.props.settings.pause)}
                  tags={util.try(() => this.props.settings.tags)} 
                  active={this.props.newsletterOpen ? 'newsletter' : null} />
                <div className='clearfix'>
                  <div className='px1 sm-px3 md-px4'>
                    <div className='container'>                      
                        <Features featured={util.try(() => this.props.page.acf)} />
                        <div className='clearfix mln1 mrn1 sm-mln3 sm-mrn3'>
                          <Listing
                              pages={util.try(() => this.props.journal.pages) || 1}
                              morePages={typeof util.try(() => this.props.journal.nextPage) === 'number'}
                              items={util.try(() => this.props.journal.posts) || []}
                              query={{exclude: util.try(() => this.props.page.exclude)}} />
                        </div>
                    </div>
                  </div>
                </div>
                <Footer menu={util.try(() => this.props.settings.footer)} />
            </div>
        );
    }
};

module.exports = Home;