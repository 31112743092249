/*
 * ShopArchive
 */

import React, {Fragment} from 'react';
import Header from '../partials/header.jsx';
import Footer from '../partials/footer.jsx';

import ArchiveListing from '../partials/listing/shop/index.jsx';
import util from '../utilities';


/**
 * Class representing the ShopArchive
 * @extends React.Component
 */
class ShopArchive extends React.Component {
  
    render() {

      const posts = this.props.posts.posts;

        return (
            <div className='main'>
                <Header 
                  url={'/shop-archive'}
                  tags={util.try(() => this.props.settings.tags)} 
                  pause={util.try(() => this.props.settings.pause)}
                  active={this.props.newsletterOpen ? 'newsletter' : null} />
                <div className='clearfix mt6 sm-mt8'>
                  
                  <div className='mx1 sm-mx3 md-mx4 ml4'>
                    <div className="container clearfix">
                      <div className="col-10 right">
                        <h1 className='mx1 sm-mx0'>Shop Archive</h1>
                      </div>
                    </div>

                    <div className='container sm-flex'>
                      <div className="sm-col-3 md-col-2 h3 caps">
                        <ul className='list-reset mt5 sm-mt0'>
                        {this.props.categories && this.props.categories.map((tag, i) => {
                          return (
                            <li className='line-height-4'>
                              <a 
                                href={`?category=${tag.slug}`}
                                className={tag.name === this.props.term ? 'border-bottom border-grey' : ''}>
                                {tag.name}
                              </a>
                            </li>
                          )
                        })}
                        </ul>
                      </div>
                      <div className="sm-col-9 md-col-10">
                        {posts && <ArchiveListing posts={posts} term={this.props.term} />}
                      </div>
                    </div>
                  </div>
                </div>
                <Footer menu={util.try(() => this.props.settings.footer)} />
            </div>
        );
    }
};

module.exports = ShopArchive;
