/*
 * Archive listing
 */

import React, {Fragment} from 'react';
import Moment from 'moment';
import Image from '../../images/image.jsx';
import moment from 'moment';
import { isArray } from '../../../utilities/index.js';


class ProductNames extends React.Component {

  pad(num, size) {
    num = num.toString();
    while (num.length < size) num = "0" + num;
    return num;
  }  
  
  render() {
    
    const productList = this.props.productList;

    return (
      <div className="mt1">
        <ul 
          className='list-reset mt1 border-top border-gray-03 pt2'>
        {productList.map((el, i) => {
          return (
            <li key={i} className='mb1 flex'>
              <div className='col-2 lg-col-1'>
                {this.pad(i+1, 2)}
              </div>
              <div className='col-10' dangerouslySetInnerHTML={{__html: el}}></div>              
            </li>
          )
        })}
        </ul>
      </div>      
    )
  }
}


class LitsRow extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
        open: props.index === 0 ? true : false
    };
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(type, e) {
    const state = {open: !this.state.open};
    this.setState(state);
  }  
   
  pad(num, size) {
    num = num.toString();
    while (num.length < size) num = "0" + num;
    return num;
  }

  render() {

    const post = this.props.post;
    const productList = [];

    return (
      <div  className='flex flex-wrap border-top border-gray'>
        <div 
          className='sm-col-3 h3 py1 caps gray' 
          onClick={this.handleClick}>
          <a>
            {moment(post.title.rendered, 'MMMM YYYY').format('MMMM')}            
          </a>
        </div>
        {this.state.open &&
          <div className='sm-col-9 h3 py1'>
            <div className='rte sm-col-11' dangerouslySetInnerHTML={{__html: post.acf.intro}}></div>
            <div className="mln1 mrn1 sm-col-10">
              <div className="mt2 flex col-12 flex-wrap">
                {post.acf.products.map(({product}, i ) => {
                  productList.push(`${product[0].title}, ${product[0].acf.price}`);
                  return (
                    <div 
                      className='col-6 sm-col-2 mb2 px1'
                      key={i}>
                        <a className='block' target='_blank' href={product[0].acf.product_url}>
                          <Image image={product[0].acf.image.sizes['custom-small']} className="col-12" />
                        </a>
                    </div>
                  )
                })}
              </div>
            </div>
            <ProductNames productList={productList} />
          </div>
        }
      </div>
    )
  }
}

class ListYear extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
        open: props.index === 0 ? true : false
    };
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(type, e) {
    const state = {open: !this.state.open};
    this.setState(state);
  }  
   

  render() {

    return (
      <div  className='border-top border-gray' >
        <div className='py1 h3 caps' onClick={this.handleClick}>
          <a className='cursor-pointer'>{this.props.year}</a>
        </div>
        {this.state.open &&
          <div>
            {this.props.posts.map((pd, i) => {
              return <LitsRow key={i} index={i} post={pd} />                  
            })}  
          </div>
        }
      </div>
    )
  }
}

/**
 * Class representing the ShopArchive
 * @extends React.Component
 */
class ArchiveListing extends React.Component {

  render() {
    
    const posts = this.props.posts;
    const productList = [];

    if(posts[0].type === 'product') {

      return (
        <div className='h3 caps'>
          <h3 className='border-bottom border-top py1 border-grey'>
            <a className="caps" href="/shop-archive">
              Back to: By date
            </a>
          </h3>
          <div className="container sm-flex mt2">
            <div className='sm-col-3 h3 caps'>
              {this.props.term}
            </div>
            <div className="sm-col-9">
              <div className="flex flex-wrap col-12 mt3 sm-mt0 mln1 mrn1">
                {posts.map((post, i) => {
                  productList.push(`${post.title.rendered}, ${post.acf.price}`);
                  return (
                    <div 
                      className='col-6 sm-col-2 px1'
                      key={i}>
                        <a target='_blank' href={post.acf.product_url}>
                          <Image image={post.acf.image.sizes['custom-small']} className="col-12" />
                        </a>
                    </div>
                  )
                })}
              </div>
              <ProductNames productList={productList} />
            </div>
          </div>
        </div>
      )
    }

    let years = [];
    posts.map((productDrop, i) => {      
      const year = moment(productDrop.title.rendered, 'MMMM YYYY').format('YYYY');      
      const found = years.find( y => y.year === year);
      if(found) {
        found.items.push(productDrop);
      } else {
        years.push({
          year: year,
          items: [productDrop]
        })
      }          
    });


    return (
      <div>
        {years.map((year, i) => {
          return (                                
            <ListYear 
              key={i}
              index={i}
              year={year.year} 
              posts={year.items} />                                    
          )
        })}
      </div>
    )

  }
}

module.exports = ArchiveListing;