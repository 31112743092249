/*
    React Component representing Footer
    change: rm credit 20-05-24
*/
import React from 'react';
import util from '../utilities';

class Footer extends React.Component {

    render () {
        const items = util.try(() => this.props.menu.map((item, i) => (
            <li key={i}>
                <a
                    href={item.url}
                    dangerouslySetInnerHTML={{__html: item.title}} />
            </li>
        )));

        return (
            <div className="clearfix bg-gray-01 z2 relative mt4 sm-mt6 md-mt8">
                <div className="mt3 sm-mt4 mb3 mx2 sm-mx3 md-mx4">
                    <div className="container">
                        <ul className="list-reset m0 h4">
                            {items}
                        </ul>
                        <div className="flex justify-between">
                          <div className="h3 mt4">
                              © Patter
                          </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

module.exports = Footer;