/*
 * Artworks listing
 */

import React, {Fragment} from 'react';
import AjaxListing from '../base/infinite-ajax-listing.jsx';
import ListingItem from './item.jsx';
import Message from '../shared/message.jsx';
import Loading from '../shared/loading.jsx';
import util from '../../../utilities';


/**
 * Class representing an Artworks listing
 * @extends React.component
 * @prop {string} path
 * @prop {object} query
 * @prop {array} items
 * @prop {function} onLoad
 * @prop {number} featured
 * @prop {boolean} morePages
 */
class HomeListing extends AjaxListing {

    /**
     * Render
     */
    render() {

        let index = 1;
        let page = 1;
        const items = util.try(() => this.state.items.map((item, i) => {

            const clearfix = index % 3 === 0 || index % 8 === 0 ? true : false;
            const featuredDesktop = (page % 2 === 0 && index === 8) || (page % 2 !== 0 && index === 7);
            const featuredMobile = (i + 1) % 3 === 0;

            const component = (
                <Fragment key={item.id}>
                    <ListingItem
                        item={item}
                        featuredDesktop={featuredDesktop}
                        featuredMobile={featuredMobile} />
                    {clearfix && (<div className='clearfix md-mb4 md-pb2' />)}
                </Fragment>
            );

            index >= 8
                ? (index = 1, page++)
                : index++;

            return component;
        }));

        return (
            <div>
                {/* Listing */}
                {this.state.status === 200 && this.state.items.length > 0 &&
                    <section className='clearfix'>
                        {items}
                    </section>
                }
                {/* Messages */}
                {this.state.loading &&
                    <Loading />
                }
                {this.state.status === 200 && this.state.items.length === 0 &&
                    <Message
                        message='Sorry, no articles found. Please try a wider search.' />
                }
                {this.state.status !== 200 &&
                    <Message
                        message='Sorry, something went wrong. Please try again later.' />
                }
            </div>
        );
    }
}

module.exports = HomeListing;
