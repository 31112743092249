import React from 'react';
class IconClose extends React.Component {

  render() {
    return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17.085" height="17.086" viewBox="0 0 17.085 17.086">
      <g id="Group_530" data-name="Group 530" transform="translate(-1309.247 -291.165)">
        <g id="Group_528" data-name="Group 528" transform="translate(1309.955 291.872)">
          <line id="Line_5" data-name="Line 5" y2="22.161" transform="translate(15.67 0) rotate(45)" fill="none" stroke="#000" stroke-width="1.5"/>
        </g>
        <g id="Group_529" data-name="Group 529" transform="translate(1309.954 307.544) rotate(-90)">
          <line id="Line_5-2" data-name="Line 5" y2="22.161" transform="translate(15.67 0) rotate(45)" fill="none" stroke="#000" stroke-width="1.5"/>
        </g>
      </g>
    </svg>
    )
  }
}

module.exports = IconClose;