import React from 'react';

class IconDot extends React.Component {

  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" className='icon-dot'>
        <circle id="Ellipse_8" data-name="Ellipse 8" cx="12" cy="12" r="12"/>
      </svg>
    )
  }
}
module.exports = IconDot;