import React, {Fragment} from 'react';

class IconRight extends React.Component {
  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="41.093" height="40.895" viewBox="0 0 41.093 40.895">
        <g id="Group_549" data-name="Group 549" transform="translate(-1849.522 -537.214)">
          <g id="Group_548" data-name="Group 548" transform="translate(0 438.988)">
            <g id="Group_547" data-name="Group 547" transform="translate(568.599 -184.279)">
              <g id="Group_528" data-name="Group 528" transform="translate(1309.955 303.115)">
                <line id="Line_5" data-name="Line 5" y2="15.897" transform="translate(11.241 0) rotate(45)" fill="none" stroke="#f5f2e4" strokeWidth="1.15"/>
              </g>
              <g id="Group_529" data-name="Group 529" transform="translate(1309.954 303.594) rotate(-90)">
                <line id="Line_5-2" data-name="Line 5" x1="0.096" y2="16.479" transform="translate(11.653 -0.068) rotate(45)" fill="none" stroke="#f5f2e4" strokeWidth="1.15"/>
              </g>
            </g>
            <g id="Group_546" data-name="Group 546" transform="translate(1158.071 -1025.706) rotate(45)">
              <g id="Group_528-2" data-name="Group 528" transform="translate(1298.421 291.872)">
                <line id="Line_5-3" data-name="Line 5" x1="28.333" y2="27.875" transform="translate(0)" fill="none" stroke="#f5f2e4" strokeWidth="1.15"/>
              </g>
            </g>
          </g>
        </g>
      </svg>
    )
  }
}

module.exports = IconRight;