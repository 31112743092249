import React from 'react';
import Moment from 'moment';
import Hammer from 'react-hammerjs';
import Image from '../images/image.jsx';
import util from '../../utilities';

class Featured extends React.Component {

    constructor(props) {
        super(props);

        this.handleClick = this.handleClick.bind(this);
        this.handleSwipe = this.handleSwipe.bind(this);
        this.autoScroll = this.autoScroll.bind(this);
        this.clearInterval = this.clearInterval.bind(this);

        this.state = {
            slide: 0
        };
    }

    componentDidMount() {
        // if (util.isArray(this.props.features) && this.props.features.length > 1) {
            //this.timeout = window.setInterval(this.autoScroll, 4000);
        // }
    }

    componentWillUnmount() {
        this.clearInterval();
    }

    clearInterval() {
        if (this.timeout) {
            window.clearInterval(this.timeout);
            this.timeout = null;
        }
    }

    autoScroll() {
        const direction = 1;
        this.moveToNextSlide(direction);
    }

    handleClick(i) {
        this.clearInterval();
        this.setState({
            slide: i
        });
    }

    moveToNextSlide(direction) {
        const lastIndex = this.props.features.length - 1;
        let nextIndex = this.state.slide + direction;
        if (nextIndex > lastIndex) {
            nextIndex = 0;
        } else if (nextIndex < 0) {
            nextIndex = lastIndex;
        }
        this.setState({
            slide: nextIndex
        });
    }

    handleSwipe(e) {
        this.clearInterval();
        const direction = e.direction - 3;
        this.moveToNextSlide(direction);
    }

    render() {

        if (!util.try(() => this.props.features.length)) {
            return (<span />);
        }

        const controllerStyle = {
            left: (100 / util.try(() => this.props.features.length) * this.state.slide) + '%'
        };

        let controls = [];
        let items = [];
        this.props.features.forEach((item, i) => {

            const activeClass = this.state.slide === i ? 'active' : '';

            controls.push(
                <span key={i}>
                    {this.state.slide === i && (
                        <span className='sm-hide h3 block bold'>
                            {`${i + 1} / ${this.props.features.length}`}
                        </span>
                    )}
                    <span
                        className={`left sm-show width-2 cursor-pointer h3 bold ${activeClass}`}
                        onClick={this.handleClick.bind(null, i)}>
                        {i + 1}
                    </span>
                </span>
            );

            const tags = item.terms.article_category.map((category, i) => (
                <a
                    key={i}
                    className='blue'
                    href={`/search?category=${category.slug}`}>
                    {category.slug}
                </a>
            ));

            items.push(
                <div
                    key={i}
                    ref={`item-${i}`}
                    className={`${activeClass} feature clearfix`}>
                    <a href={item.slug} className='block'>

                        <div className='col-9 md-col-12 col-right'>
                            <h1 className='md-col-8 my0 sm-pr3 md-pr4'>{item.title}</h1>
                        </div>

                        <div className='sm-mrn3 md-mrn4 lg-m0'>
                            <Image image={item.acf.lead_image} className='pt4 col-12' />
                        </div>

                    </a>
                    <div className='col-right md-col col-9 md-col-8'>
                        <span className='h3 caps block mt2 sm-mt3 mb0'>
                            {Moment(item.post_date).format('MMM-YYYY')} {tags}
                        </span>
                        <p className='my2 mr1 h5 md-col-8'>
                            {item.acf.standfirst}
                        </p>
                    </div>
                </div>
            );
        });

        return (
            <div className='clearfix features mt8 sm-mt4'>
                <div className='md-mt4 col col-3 sm-col-2'>
                    {this.props.features.length > 1 &&
                        <div className='list-reset sm-mt1 relative left z1'>
                            {controls}
                            <span
                                className='sm-show absolute bottom-0 mbn1 marker h2 bold width-2'
                                style={controllerStyle}>
                                _
                            </span>
                        </div>
                    }
                </div>
                {this.props.features.length > 1 &&
                    <Hammer
                        onSwipe={this.handleSwipe}>
                        <div className='col-right col-12 md-col-10 relative mtn3 md-mt4 regular'>
                            {items}
                        </div>
                    </Hammer>
                }
                {this.props.features.length === 1 &&
                    <div className='col-right col-12 md-col-10 relative mtn3 md-mt4 regular'>
                        {items}
                    </div>
                }
            </div>
        );

    }
}
module.exports = Featured;
