import React from 'react';

class Text extends React.Component {
    render() {
        return (
            <div className="clearfix container">
                {this.props.title &&
                <div className="col-right sm-col col-11 sm-col-3 md-col-4">
                    <h2 className='h4 bold mt5 mb0 mr3 md-mr6'>{this.props.title}</h2>
                </div>}
                <div className="col-right col-11 sm-col-9 md-col-8">
                    <div className="rte mt2 sm-mt5" dangerouslySetInnerHTML={{__html: this.props.content}}></div>
                </div>
            </div>
        );
    }
}

module.exports = Text;
