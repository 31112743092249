/*
 * Error page
 */

import React from 'react';
import util from '../utilities';


/**
 * Class representing the error pages
 * @extends React.component
 * @prop {object} page
 * @prop {object} settings
 * @prop {object} error
 */
class ErrorPage extends React.Component {
    render() {
        return (
            <span />
        );
    }
};

module.exports = ErrorPage;
