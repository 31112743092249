import React from 'react';
import Moment from 'moment';
import CategoryLabel from './category-label.jsx';
import Image from './images/image.jsx';

class ListingItem extends React.Component {

    render() {
        return (
            <div className={`col ${this.props.className || 'col-4'} mt2 md-mt0`}>
                <div className='hover-sibling col col-5 md-col-12'>
                    <div className="px1 sm-px3">
                        <a href={this.props.item.slug} className="block">
                            <Image image={this.props.item.acf.lead_image} className="col-12" />
                        </a>
                    </div>
                </div>
                <div className="hover-container col-right col-7 md-col-12">
                    <div className="pl1 pr2 sm-px3">
                        <a href={this.props.item.slug}>
                            <h3
                                className="hover-target-blue mt0 md-mt2 h4 mb0"
                                dangerouslySetInnerHTML={{__html: this.props.item.title.rendered || this.props.item.title}}/>
                        </a>
                        <span className="caps h3 block mt1 md-mt2 line-height-2">
                            <span>{Moment(this.props.item.date).format('MMM-YYYY')}</span>
                            {this.props.item.article_category && (
                                <span className='ml1'>
                                    <CategoryLabel categories={this.props.item.article_category} />
                                </span>
                            )}
                        </span>
                        <p
                            className="mb0 h5 mt1 md-mt2"
                            dangerouslySetInnerHTML={{__html: this.props.item.acf.standfirst}} />
                    </div>
                </div>
            </div>
        );
    }
}

module.exports = ListingItem;
