/*
 * Case Studies
 */

import React, {Fragment} from 'react';
import Image from '../../images/image.jsx';
import IconLeft from '../../ui/icon-left.jsx';
import IconRight from '../../ui/icon-right.jsx';
// import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext, DotGroup } from 'pure-react-carousel';

class Carousel extends React.Component {

  constructor(props) {
    super(props);

    this.handleNext = this.handleNext.bind(this);
    this.handlePrevious = this.handlePrevious.bind(this);

    this.state = {
      current: 0,
      length: this.props.length     
    };
  }  

  handleNext(){
    let desired = this.state.current + 1;
    if(desired >= this.state.length) {
      desired = 0;
    }
    this.setState({current: desired});
  }
  handlePrevious(){
    const desired = (this.state.current) <= 0 ? this.state.length-1 : this.state.current - 1;
    this.setState({current: desired});
  }

  render() {

    return (
      <div>
        <div className="container">
          <div 
            className="overflow-hidden col-12">
              <div 
                className="flex animate" 
                style={{
                  width: `${this.props.length*100}%`,
                  transform: `translate(-${(this.state.current*100)/this.props.length}%, 0)`             
                }}>
                {this.props.children}
              </div>
          </div>
        </div>
        <div className='absolute top-0 left-0 right-0 flex justify-between items-center height-100 col-12'>
          <button 
            className='sm-mln2 bg-transparent border-0 cursor-pointer height-100'
            onClick={this.handlePrevious}>
            <IconLeft />
          </button>
          <button 
            className='sm-mrn2 bg-transparent border-0 cursor-pointer height-100'
            onClick={this.handleNext}>
            <IconRight />
          </button>
        </div>
      </div>
    )
  }
}

/**
 * Class representing the CaseStudies
 * @extends React.Component
 */
class CaseStudies extends React.Component {
  render() {
    return (
      <div className='relative'>
        <Carousel
          length={this.props.projects.length}
          className="col-12">
            {this.props.projects.map((project, index) => {
              return (
                <div 
                  className="sm-flex flex-wrap white col-12"
                  key={index}>
                  <div className="col-12 sm-col-6 flex justify-center sm-justify-end items-center">
                    <div className={project.image.height>project.image.width ? 'col-10 sm-col-9' : 'col-10 sm-col-12'} >
                      <Image
                        className="col-12" 
                        image={project.image} />
                    </div>
                  </div>
                  <div className="col-12 sm-col-6 flex justify-center sm-items-center h3 caps center mt2 sm-mt0">
                    <div>
                      <div className="mb4 opacity-8">
                        {index+1} / {this.props.projects.length}
                      </div>
                      <div>
                        {project.client}
                      </div>
                      —
                      <div className='pre'>
                        {project.info}
                      </div>
                    </div>
                  </div>
                </div>                
              )
            })}          
        </Carousel>
      </div>
    )
  }
}

module.exports = CaseStudies;