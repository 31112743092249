/**
 Article Detail Template
*/

import React, {Fragment} from 'react';
import Moment from 'moment';
import util from '../utilities';
import Header from '../partials/header.jsx';
import Footer from '../partials/footer.jsx';
import Image from '../partials/images/image.jsx';

/* modules */
import Text from '../partials/module/text.jsx';
import Quote from '../partials/module/quote.jsx';
import RelatedInline from '../partials/module/related-inline.jsx';
import Contributors from '../partials/module/contributors.jsx';
import Share from '../partials/module/share.jsx';
import ListingItem from '../partials/listing-item.jsx';
import LightboxItem from '../partials/ui/lightbox-item.jsx';
import Lightbox from '../partials/ui/lightbox.jsx';

/**
 * Class representing the Article
 * @extends React.component
 */
class Article extends React.Component {

    constructor(props) {
        super(props);
        this.handleLightboxClick = this.handleLightboxClick.bind(this);

        // reduce modules
        this.images = util.try(() => this.props.page.acf.modules.reduce((res, obj, i) => {
            obj.acf_fc_layout === 'image'
                ? res.push(...obj.image)
                : null;
            return res;
        }, []));

        this.leadImage = this.props.page.acf.lead_image || this.props.page.acf.listing_image;

        if (this.leadImage) {
            this.images.unshift(this.leadImage);
        }

        this.state = {
            currentLightboxIndex: false
        };
    }

    handleLightboxClick(id) {
        this.setState({currentLightboxIndex: id});
    }

    render() {

        // Loop our modules
        const modules = util.try(() => this.props.page.acf.modules.map((module, i) => {

            switch(module.acf_fc_layout) {

            case 'text':
                return (
                    <Text
                        key={i}
                        title={module.sub_title}
                        content={module.text} />
                );

            case 'pull_quote':
                return (
                    <Quote
                        key={i}
                        content={module.pull_quote}
                        cite={module.cite} />
                );

            case 'image':
                let wrapClass = '';
                let containerClass = '';

                if (module.type === '12-col') {
                    containerClass = 'container';
                    wrapClass = 'col-12';
                } else if( module.type === '8-col') {
                    containerClass = 'container clearfix';
                    wrapClass = 'col-right col-12 sm-col-9 md-col-8';
                }

                // double images
                if(module.type === 'double') {
                    // render side by side
                    return (
                        <div key={i} className='container clearfix'>
                            <div className="mt4">
                                <div className="col col-6">
                                    <div className="mr1">
                                        <LightboxItem
                                            onHandleClick={this.handleLightboxClick}
                                            className='col-12'
                                            image={module.image[0]} />
                                        {module.image[0].caption && (
                                            <span className='h3 mt1 md-mt2 block caps'>
                                                {module.image[0].caption}
                                            </span>
                                        )}
                                    </div>
                                </div>
                                <div className="col col-6">
                                    <div className="ml1">
                                        <LightboxItem
                                            onHandleClick={this.handleLightboxClick}
                                            className='col-12'
                                            image={module.image[1]} />
                                        {module.image[1].caption && (
                                            <span className='h3 mt1 md-mt2 block caps'>
                                                {module.image[1].caption}
                                            </span>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                }

                return (
                    <div key={i} className={containerClass}>
                        <div className={`${wrapClass} clearfix mt4`}>
                            <LightboxItem
                                onHandleClick={this.handleLightboxClick}
                                className='col-12'
                                image={module.image[0]} />
                            {module.image[0] && module.image[0].caption && (
                                <span className='h3 mt1 md-mt2 block caps'>
                                    {module.image[0].caption}
                                </span>
                            )}
                        </div>
                    </div>
                );

            case 'related_content_inline':
                return (
                    <RelatedInline
                        key={i}
                        related={module.related} />
                );

            case 'related_content':
                const relatedContent = module.related.map((item, i) => (
                    <Fragment key={i}>
                        <ListingItem
                            item={item}
                            className='col-12 md-col-6' />
                        {(i + 1) % 2 === 0 && (<div className='clearfix md-mb5'/ >)}
                    </Fragment>
                ));

                return (
                    <div key={i} className='clearfix container'>
                        <div className='col col-12 md-col-4'>
                            <h3 className='mt8 mb0 caps'>
                                Related Content
                            </h3>
                        </div>
                        <div className='col col-12 md-col-8'>
                            <div className='mt0 md-mt8 mxn1 sm-mxn3'>
                                {relatedContent}
                            </div>
                        </div>
                    </div>
                );

            case 'contributors':
                return (
                    <Contributors
                        key={i}
                        contributors={module.contributor} />
                );

            case 'share':
                return (
                    <Share
                        key={i}
                        title={this.props.title}
                        url={`${this.props.settings.url}${this.props.page.uri}`} />
                );
            }
        }));

        const tags = util.try(() => this.props.page.article_category.map((category, i) => (
            <a
                key={i}
                className='blue'
                href={`/search?category=${category.slug}`}>
                {category.slug}
            </a>
        )));

        const leadImage = this.props.page.acf.lead_image || this.props.page.acf.listing_image;

        return (
            <div className='main'>
                <Header 
                  url={'/read'}
                  pause={util.try(() => this.props.settings.pause)}
                  tags={util.try(() => this.props.settings.tags)} />

                <article className='mt6 sm-mt8'>
                    <div className="mx1 sm-mx3 md-mx4">
                        <div className='container clearfix'>
                            <div className='col-right col-9 md-col-10'>
                                <h1
                                    className='col-12 md-col-8 m0 mt4'
                                    dangerouslySetInnerHTML={{__html: this.props.title}} />
                            </div>
                        </div>
                    </div>
                    <div className="ml1 sm-ml3 md-ml4 md-mr4">
                        <div className='relative clearfix'>
                            <div className='container'>
                                <div className='upright top-0 h3 absolute caps gray mt2 sm-mt3 md-mt4'>
                                    <span className='mr1'>
                                        {Moment(this.props.page.date).format('DD-MM-YYYY')}
                                    </span>
                                    <span>
                                        {tags}
                                    </span>
                                </div>
                                {this.leadImage && (
                                    <div className='col-right col-11 md-col-10 mt2 sm-mt3 md-mt4'>
                                        <div className="sm-mrn3 md-mrn4 lg-m0">
                                            <LightboxItem
                                                className='col-12'
                                                onHandleClick={this.handleLightboxClick}
                                                image={this.leadImage} />
                                            {leadImage.caption && (
                                                <span className='h3 mt1 md-mt2 block caps'>
                                                    {this.leadImage.caption}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className='clearfix mr1 sm-mr3 md-mr0'>
                            {modules}
                        </div>
                    </div>
                </article>

                {this.images && this.state.currentLightboxIndex && (
                    <Lightbox
                        onHandleClick={this.handleLightboxClick}
                        currentIndex={this.state.currentLightboxIndex}
                        images={this.images} />
                )}

                <Footer menu={this.props.settings.footer} />
            </div>
        );

    }
};

module.exports = Article;
