
import React, {Fragment} from 'react';
import Header from '../partials/header.jsx';
import Footer from '../partials/footer.jsx';
import CaseStudies from '../partials/studio/case_study';
import util from '../utilities';

class StudioPage extends React.Component {
  
  render() {

    // const caseStudies = this.props.acf
    // console.log(this.props)

    return (
      <div className='main bg-brown pb4 sm-pb8 studio-page'>
        <Header
          studio={true}
          url={'/studio'}
          pause={util.try(() => this.props.settings.pause)}
          tags={util.try(() => this.props.settings.tags)} 
          active={this.props.newsletterOpen ? 'newsletter' : null} />
        
        <div className='mt3 sm-mt5 mx2 sm-mx3 md-mx4'>

          {this.props.page.acf.case_study && <CaseStudies projects={this.props.page.acf.case_study} />}
          
          <div className="container studio-white pb8">

            <div className="mt4">

            {this.props.page.acf.columns.map((column, i) => {
              return (
                <div key={i} className="sm-flex justify-between mt6">
                  <div className="col-12 sm-col-6">
                    <div
                      className='rte sm-pr4'                   
                      dangerouslySetInnerHTML={{__html: column.left_column}}></div>
                  </div>
                  <div className="col-12 sm-col-6">
                  <div 
                    className='sm-pl4 rte'
                    dangerouslySetInnerHTML={{__html: column.right_column}}></div>
                  </div>
                </div>
              )
            })}     
            </div>     

          </div>

        </div>
        <footer className='bg-studio-black px2 sm-px3 md-px4 fixed bottom-0 col-12'>
          <div className="container white h5 sm-h3">
            <div className="flex justify-between py2 sm-py3 caps">
              <a 
                target='_blank'
                className='mr3'
                href="mailto:studio@patterlondon.com">
                studio@patterlondon.com
              </a>
              <a 
                target='_blank' 
                href="https://www.instagram.com/_patter/">
                @_PATTER
              </a>
            </div>
          </div>
        </footer>
        {/* <Footer menu={util.try(() => this.props.settings.footer)} /> */}
      </div>
    )
  }

}

module.exports = StudioPage;