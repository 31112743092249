import React from 'react';
import Moment from 'moment';
import Image from '../images/image.jsx';

class Related extends React.Component {

    render() {

        // Loop related
        const related = this.props.related.map((item, i) => {
            const itemClass = i > 0 ? 'sm-show' : '';
            return (
                <div
                    key={i}
                    className={`col col-12 sm-col-4 ${itemClass}`}>
                    <div className=''>
                        <div  className='sm-mx1 clearfix'>
                            <div className='hover-sibling overflow-hidden relative col col-6 sm-col-12'>
                                <div className='aspect-3-2 relative'>
                                    <div className='absolute bottom-0 top-0'>
                                        <a href={item.slug}>
                                            <Image
                                                className='relative height-100'
                                                image={item.acf.lead_image} />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className='hover-container col col-6 sm-col-12'>
                                <div className='mx2 sm-mx0 pb2 md-pb3'>
                                    <a className='hover-target' href={item.slug}>
                                        <h4 className='mt0 sm-mt2 mb0 gray'>
                                            {item.title}
                                        </h4>
                                    </a>
                                    <span className='h3 m0 caps gray'>
                                        {Moment(item.post_date).format('MMM-YYYY')}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        });

        return (
            <div className='clearfix container'>
                <div className='col col-12 sm-col-3 md-col-4 md-show'>
                    <h3 className='h3 caps mt4'>Related Content</h3>
                </div>
                <div className='col-right col-12 md-col-8 mt4 py2 md-p0 md-pt3 border-top border-bottom border-grey'>
                    <h2 className='h3 caps upright absolute mln1 gray sm-show md-hide'>Related Content</h2>
                    <div className='col-12 sm-col-11 md-col-12 col-right'>
                        {related}
                    </div>
                </div>
            </div>
        );

    }
}

module.exports = Related;
