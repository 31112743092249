/*
 * React Component representing Header
*/

import React from 'react';
import Moment from 'moment';

import util from '../utilities';
import Logo from './ui/logo.jsx';
import PauseVideo from './pause/index.jsx';

class Dropdown extends React.Component {

    constructor() {
        super();
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    componentDidMount() {
        if (window) {
            window.addEventListener('keydown', this.handleClose, false);
        }
    }

    componentWillUnmount() {
        window.removeEventListener('keydown', this.handleClose, false);
    }

    handleClose(e) {
        if (this.props.active &&
            e.keyCode === 27 ||
            e.type === 'click') {
            this.props.onHandleClose();
        }
    }
}

class Newsletter extends Dropdown {
    constructor() {
        super();

        this.state = {
            message: false,
            name: '',
            email: '',
            loading: false,
            subscribed: false
        };
    }

    componentWillReceiveProps(props) {
        if (props.active) {
            this.name.focus();
        } else {
            this.name.blur();
            this.setState({
                message: false
            });
        }
    }

    handleChange(type, e) {
        const state = {[type]: e.target.value};
        this.setState(state);
    }

    handleSubmit(e) {
        e.preventDefault();
        if (this.validate()) {
            this.setState({
                loading: true
            }, this.postForm);
        } else {
            this.updateMessage('invalid');
        }
    }

    validate() {
        if (!this.state.subscribed) {
            return true;
        }
        return false;
    }

    updateMessage(response) {
        const message = {
            'invalid': 'Please provide a valid email address.',
            'success': 'Thank you for signing-up. You will receive a confirmation email shortly.',
            'duplicate': 'This email address is already signed-up to the mailing list.',
            'error': 'There was a problem signing-up, please try again later.'
        };

        let state = {
            message: message[response],
            loading: false
        };

        if(response === 'success') {
            state = {
                ...state,
                subscribed: true,
                email: '',
                name: ''
            };
        }

        this.setState(state);
    }

    postForm() {
        const post = fetch('/subscribe', {
            method: 'POST',
            body: new FormData(this.form)
        });

        post.then((res) => res.json())
            .then((json) => this.updateMessage(json.message))
            .catch((err) => this.updateMessage('error'));
    }

    render() {
        return (
            <div className={`sm-mxn3 md-m0 reveal top-0 ${this.props.active ? 'reveal-active' : 'reveal-hidden'}`}>
                <div className='relative bg-tomato white pb4'>
                    <form
                        ref={(el) => this.form = el}
                        onSubmit={this.handleSubmit}
                        autoComplete='off'>

                        {/* Logo */}

                        <div className='md-hide absolute top-0 right-0 col-9 sm-col-12 mt3 sm-pl3'>
                            <div className="logo nl-logo">
                              <Logo />
                            </div>
                        </div>

                        {/* Close */}
                        <div className='md-show absolute top-0 left-0 py3 pl20p h3'>
                            {Moment().format('DD MMMM YYYY')}
                        </div>
                        <div className='absolute top-0 right-0 p3 h3'>
                            <div
                                onClick={this.props.onHandleClose}
                                className='right icon-cross cursor-pointer' />
                        </div>

                        {/* Text */}
                        <div className='clearfix md-pl20p'>
                            <div className='col col-right col-9 sm-col-12 pr3 sm-px3 md-p0 md-pr3'>
                                <h4 className='newsletter__heading h1 m0 mb2 sm-mb3 md-mb6'>Welcome</h4>
                            </div>
                            <div className='col col-12 px3 md-p0 md-pr3'>
                                <p className='m0 mb3'>
                                  Subscribe to the monthly Patter newsletter to receive new ideas and useful recommendations.
                                </p>
                                <p className='md-show mb2 sm-mb3 md-mb6'>
                                  Patter finds beauty and pleasure in the everyday - for a life less cluttered and more thoughtful.
                                </p>
                            </div>
                        </div>

                        {/* Form */}
                        <div className='px3 md-pl20p md-pr20p'>
                            <div className='border-bottom border-white clearfix'>
                                <label className='py1 left' htmlFor='name'>
                                    Name
                                </label>
                                <div className='overflow-hidden'>
                                    <input
                                        ref={(el) => this.name = el}
                                        onChange={this.handleChange.bind(null, 'name')}
                                        value={this.state.name}
                                        className='col-12 bg-transparent border-0 p1 btn light'
                                        type='text'
                                        name='fname' />
                                </div>
                            </div>
                            <div className='border-bottom border-white clearfix'>
                                <label className='py1 left' htmlFor='email'>
                                    Email
                                </label>
                                <div className='overflow-hidden'>
                                    <input
                                        ref={(el) => this.email = el}
                                        onChange={this.handleChange.bind(null, 'email')}
                                        value={this.state.email}
                                        type='email'
                                        name='email'
                                        className='col-12 bg-transparent border-0 p1 btn light' />
                                </div>
                            </div>
                            <div className='mt1'>
                                <button className='m0 p0 btn light' type='submit'>
                                    Sign up
                                </button>
                            </div>
                            {this.state.message && (
                                <div className='clearfix mt4'>
                                    {this.state.message}
                                </div>
                            )}
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

class Search extends Dropdown {

    constructor() {
        super();

        this.state = {
            term: '',
        };
    }

    componentWillReceiveProps(props) {
        if (props.active) {
            this.input.focus();
        } else {
            this.input.blur();
            this.setState({term: ''});
        }
    }

    handleChange(e) {
        this.setState({term: e.target.value});
    }

    handleSubmit(e) {
        e.preventDefault();
        window.location = `/search?term=${this.state.term}`;
    }

    render() {
        return (
            <div className={`md-m0 col-12 top-0 search-box`}>
                <div className='relative sm-px5 sm-py6 h4'>
                    <form
                      className='flex justify-between border-bottom border-black pb1 sm-mx1'
                        onSubmit={this.handleSubmit}
                        autoComplete='off'>                     
                        <div className='mt2'>
                            <input
                                ref={(el) => this.input = el}
                                onChange={this.handleChange}
                                value={this.state.term}
                                className='border-0 h2 col-12 bg-transparent'
                                type='text'
                                name='query'
                                placeholder='' />
                        </div>
                        <div
                            onClick={this.handleClose}
                            style={{fontSize: '0.8rem'}}
                            className='icon-cross cursor-pointer mt3 sm-mt5' />                           
                        <button className='white ml1 hide' type='submit'>
                            Submit
                        </button>
                    </form>
                </div>
            </div>
        );
    }
}

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.toggleReveal = this.toggleReveal.bind(this);
        this.toggleMenu = this.toggleMenu.bind(this);
        this.togglePause = this.togglePause.bind(this);
        this.state = {
            active: this.props.active || null,
            menu: false,
            pauseActive: false
        };
    }

    checkScrollPos(e) {      
      const header = document.querySelector('header');
      if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
        header.classList.add('active');
      } else {
        header.classList.remove('active');
      }
    }

    componentDidMount() {
        if (util.try(() => document)) {
            if (!util.cookies.hasItem('newsletter')) {
                this.setState({
                    active: 'newsletter'
                });
                util.cookies.setItem('newsletter', 1, null, '/');
            }
            window.addEventListener("scroll", this.checkScrollPos)
        }
    }

    /**
     * Generic reveal toggle
     *  @var {string} key - reveal name
     */
    toggleReveal(key) {
        const active = this.state.active !== key ? key : null;
        this.setState({active});
    }

    toggleMenu(){
      this.setState({ menu: !this.state.menu })
    }

    togglePause(e) {
      e.preventDefault();
      this.setState({ pauseActive: !this.state.pauseActive })      
    }


    render() {

      const tagsHeight = this.props.tags ? this.props.tags.length * 38 : 200;
        return (
          <React.Fragment>
            <header className={`sticky top-0 col-12 pb2 z3 ${this.props.studio ? 'bg-brown white' : 'bg-white'}`}>
                <div className='relative mx2 sm-mx3 md-mx4'>
                    <div className='container'>
                        <div className='z3 clearfix relative col-12 sm-col-6 md-col-5 mx-auto'>
                            {/* Newsletter dropdown */}
                            <Newsletter
                                onHandleClose={this.toggleReveal.bind(null, 'newsletter')}
                                active={this.state.active === 'newsletter'} />

                            {/* Search Dropdown */}
                        </div>
                        <div className='clearfix container'>
                            <div className={`col-right ${this.props.studio ? 'col-12' : 'col-9 sm-col-10'}`}>
                                <div className='z2 flex justify-between sm-items-end col-12'>
                                    {this.props.studio && 
                                      <div className='col-4 h3 caps white flex items-end'>
                                        <span className="sm-show">Patter Studio</span>
                                        <span className='sm-hide'>Studio</span>
                                      </div>
                                    }
                                    
                                    <a href='/' className='logo mt4 flex'>
                                      <Logo color={this.props.studio ? 'white' : 'black'} />                                        
                                    </a>

                                    <div className={`nav-wrapper fixed top-0 left-0 sm-relative relative height-100 sm-height-auto ${this.state.menu ? '' : 'sm-show'}`}>
                                      <div className="nav-inner-wrap right clearfix">
                                        <a href='/' className='sm-hide mx1 logo mt4 block'>
                                          <Logo color={this.props.studio ? 'white' : 'black'} />                                        
                                        </a>                                      
                                        <ul className='relative primary px1 pr2 sm-px5 pt5 list-reset h3 caps m0 line-height-1 cursor-pointer sm-mln1 sm-mrn1 sm-flex'>
                                            <li className='sm-inline sm-px1'>
                                              <span className="relative">
                                                <span>Subscribe</span>
                                                <div className="sub-nav">
                                                  <ul className='list-reset'>
                                                    <li>
                                                      <span onClick={this.toggleReveal.bind(null, 'newsletter')}>
                                                        Newsletter
                                                      </span>
                                                    </li>
                                                    <li>
                                                      <a 
                                                        href="https://www.instagram.com/_patter/?hl=en-gb" 
                                                        target='_blank'>
                                                          Follow
                                                      </a>
                                                    </li>
                                                  </ul>
                                                </div>
                                              </span>
                                              <div className="mask"></div>
                                            </li>
                                            <li className='sm-inline sm-px1'>
                                              <span className="relative">
                                                <span className={this.props.url === '/read' ? 'link-active' : ''}>
                                                  Read
                                                </span>
                                                <div className="sub-nav">
                                                  <ul className='list-reset'>
                                                    {this.props.tags && this.props.tags.map((tag,i) => {
                                                      return (
                                                        <li key={i}>
                                                          <a href={`/search?category=${tag.slug}`}>
                                                            {tag.name}
                                                          </a>
                                                        </li>
                                                      )
                                                    })}
                                                  </ul>
                                                </div>
                                              </span>
                                              <div className="mask" style={{height: `${tagsHeight}px`}}></div>
                                            </li>
                                            <li className='sm-inline sm-px1'>
                                              <span className="relative">
                                                <span className={this.props.url === '/shop' || this.props.url === '/shop-archive' ? 'link-active' : ''}>
                                                  Shop
                                                </span>
                                                <div className="sub-nav">
                                                  <ul className='list-reset'>
                                                    <li>
                                                      <a                                                        
                                                        href='/shop'>
                                                        New
                                                      </a>
                                                    </li>
                                                    <li>
                                                      <a                                                         
                                                        href='/shop-archive'>
                                                        Archive
                                                      </a>
                                                    </li>
                                                  </ul>                                              
                                                </div>
                                              </span>
                                              <div className="mask"></div>
                                            </li>                                             
                                            <li className='sm-inline sm-px1'>
                                              <span className="relative">
                                                <a href='/studio' className={this.props.url === '/studio' ? 'link-active' : ''}>
                                                    Studio
                                                </a>
                                              </span>                                              
                                            </li>
                                            {this.props.pause && this.props.pause.enable &&
                                              <li>
                                                <a onClick={this.togglePause} href="#">
                                                  Pause
                                                </a>
                                              </li>
                                            }
                                            <li className='relative sm-static sm-inline sm-px1 search-form'>
                                                <span className='inline-block relative z1' onClick={this.toggleReveal.bind(null, 'search')}>
                                                  <span className="sm-hide">Search</span>
                                                  <svg xmlns="http://www.w3.org/2000/svg" className="sm-show" width="16.03" height="17.03" viewBox="0 0 17.03 18.03">
                                                    <g id="Group_584" data-name="Group 584" transform="translate(-1733 -37)">
                                                      <g id="Ellipse_3" data-name="Ellipse 3" transform="translate(1733 37)" fill="none" stroke={this.props.studio ? 'white' : 'black'} strokeWidth="1.5">
                                                        <circle cx="7" cy="7" r="7" stroke="none"/>
                                                        <circle cx="7" cy="7" r="6.25" fill="none"/>
                                                      </g>
                                                      <line id="Line_6" data-name="Line 6" x2="5" y2="5" transform="translate(1744.5 49.5)" fill="none" stroke={this.props.studio ? 'white' : 'black'} strokeWidth="1.5"/>
                                                    </g>
                                                  </svg>
                                                </span>
                                                <div className={this.state.active === 'search' ? 'block' : 'hide'}>                   
                                                  <div className="absolute left-0 top-0 col-12 pt6 sm-pt0">
                                                    <Search
                                                      onHandleClose={this.toggleReveal.bind(null, 'search')}
                                                      active={this.state.active === 'search'} /> 
                                                  </div>                                               
                                                </div>
                                                {this.state.active === 'search' && <div className="mask block"></div>}
                                            </li>                                          
                                        </ul>
                                      </div>
                                    </div>

                                    <button 
                                      onClick={this.toggleMenu}
                                      className='col-4 justify-end border-0 bg-transparent sm-hide z2 relative caps h3 flex items-end black sm-white p0'>
                                      <span>Menu</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            {this.state.pauseActive && this.props.pause &&
              <PauseVideo 
                pause={this.props.pause}
                handleClose={this.togglePause}  />
            }
          </React.Fragment>
        );
    }
}

module.exports = Header;
