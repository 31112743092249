import React from 'react';

class Logo extends React.Component {

  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="91.461" height="25.769" viewBox="0 0 91.461 25.769">
      <defs>
        <clipPath id="clippath">
          <path id="Path_748" data-name="Path 748" d="M0-160.678H91.461v-25.769H0Z" transform="translate(0 186.447)" fill="none"/>
        </clipPath>
      </defs>
      <g id="Group_452" data-name="Group 452" transform="translate(0 186.447)">
        <g id="Group_451" data-name="Group 451" transform="translate(0 -186.447)" clipPath="url(#clippath)">
          <g id="Group_450" data-name="Group 450" transform="translate(0 0)">
            <path id="Path_747" fill={this.props.color ? this.props.color : 'black'} data-name="Path 747" d="M0-157.376H5.3v-9.143h6.213c5.3,0,8.437-3.212,8.437-7.978a8.078,8.078,0,0,0-2.259-5.754,8.843,8.843,0,0,0-6.6-2.365H0Zm5.3-20.792h5.366c2.542,0,4.024,1.342,4.024,3.707,0,2.295-1.412,3.777-4.095,3.777H5.3Zm27.444,20.792h4.624v-.177c-.424-.318-.635-1.13-.635-2.506v-9.213c0-4.236-2.189-6.672-7.625-6.672-5.3,0-7.66,2.789-7.8,5.966h4.271c.141-1.73,1.235-2.542,3.495-2.542,2.012,0,3.036.847,3.036,2.153,0,1.377-1.377,1.624-4.377,2.047-4.024.565-7.272,1.836-7.272,5.825,0,3.6,2.612,5.542,6.319,5.542,3.106,0,4.413-1.059,5.33-2.436h.071a4.909,4.909,0,0,0,.565,2.012m-4.8-2.93c-1.694,0-2.824-.6-2.824-2.294s1.235-2.259,3.742-2.824a13.383,13.383,0,0,0,3.354-1.059v2.718c0,1.765-1.518,3.459-4.271,3.459m10.115-12h2.506v10.625c0,3.424,2.577,4.342,4.907,4.342,1.906,0,3-.071,3-.071v-3.53s-.812.035-1.412.035a1.589,1.589,0,0,1-1.8-1.8v-9.6H48.36v-3.177H45.254v-5.648H40.559v5.648H38.052Zm11.745,0H52.3v10.625c0,3.424,2.577,4.342,4.907,4.342,1.906,0,3-.071,3-.071v-3.53s-.812.035-1.412.035a1.589,1.589,0,0,1-1.8-1.8v-9.6h3.106v-3.177H57v-5.648H52.3v5.648H49.8Zm20.885,15.462c4.66,0,7.519-2.718,8.19-5.93h-4.73a3.38,3.38,0,0,1-3.495,2.224c-2.718,0-4.271-1.73-4.624-4.518H79.154c0-6.46-3.106-10.908-8.931-10.908-5.3,0-8.931,4.165-8.931,9.531,0,5.4,3.389,9.6,9.39,9.6m-.388-15.426a3.819,3.819,0,0,1,3.848,3.883H66.093c.424-2.365,1.659-3.883,4.2-3.883m15.484-3.212H81.153v18.109h4.8V-166.2c0-3.812,2.259-5.472,5.4-5.154h.106v-4.2a3.132,3.132,0,0,0-1.13-.141c-2.118,0-3.354,1.059-4.448,3.106h-.106Z" transform="translate(0 182.616)"/>
          </g>
        </g>
      </g>
      </svg>
    )
  }
}
module.exports = Logo;