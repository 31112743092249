/*
 * Search listing container
 */

import React from 'react';
import ListingContainer from '../base/container.jsx';
import Pagination from '../shared/pagination.jsx';
import Listing from './listing.jsx';
import util from '../../../utilities';


/**
 * Class representing a Search listing container
 * @extends React.component
 * @prop {array} items
 * @prop {number} pages
 * @prop {boolean} morePages
 */
class SearchListingContainer extends ListingContainer {

    render() {

        return (
            <section className='relative'>

                {/* Listing */}
                <Listing
                    path='/api/search'
                    items={this.props.items}
                    query={{
                        page: this.state.page
                    }} />

                <Pagination
                    current={this.state.page}
                    total={this.props.total}
                    navigate={this.handlePage} />

            </section>
        );

    }
}

module.exports = SearchListingContainer;
