import React from 'react';

class Contributors extends React.Component {

    render() {

        const contributors = this.props.contributors.map((item, i) => {
            return (
                <div key={i} className="clearfix">
                    <div className="col-right sm-col col-11 sm-col-3 md-col-4">
                        <span className="h3 caps mt2 sm-mt0 block">{item.title}</span>
                    </div>
                    <div className="col-right col-11 sm-col-9 md-col-8 h3 mt1 sm-mt0 rte">
                        <div
                            className="col-10 md-col-8 rte"
                            dangerouslySetInnerHTML={{__html: item.copy}} />
                    </div>
                </div>);
        });

        return (
            <div className="container">
                <div className="mt6">
                    {contributors}
                </div>
            </div>
        );
    }
}

module.exports = Contributors;
