/*
 * Search Listing
 */

import React, {Fragment} from 'react';
import Moment from 'moment';
import util from '../utilities';

import Header from '../partials/header.jsx';
import Footer from '../partials/footer.jsx';
import Featured from '../partials/home/featured.jsx';
import Listing from '../partials/listing/search/index.jsx';

/**
 * Class representing the Search listing
 * @extends React.component
 */
class Search extends React.Component {

    render() {

        // Loop our terms
        const tags = this.props.tags.map((item, i) =>  (
            <li className='inline sm-block' key={i}>
                <a href={`/search?category=${item.slug}`}>
                    {item.name}
                </a>
                {i !== this.props.tags.length - 1 && (
                    <span className='sm-hide'>, </span>
                )}
            </li>
        ));

        return (
            <div className="main">
                <Header 
                  pause={util.try(() => this.props.settings.pause)}
                  tags={util.try(() => this.props.settings.tags)}  />

                <div className='clearfix container overflow-hidden'>
                    <div className='clearfix my5'>
                        <div className='clearfix container'>
                            <div className='mt4 sm-mt9 col-right col-9 md-col-10'>
                                <h1 className='ml1 sm-ml3 md-ml0 m0 blue'>
                                    {this.props.term}
                                </h1>
                            </div>
                        </div>
                        <div>
                            <ul className='col-right sm-col col-9 sm-col-12 pl1 sm-pl3 list-reset h3 caps gray'>
                                {tags}
                            </ul>
                            <div className='col col-12 ml2 sm-ml3 mt4 caps h3'>
                                {`${this.props.posts.total} Result${this.props.posts.total === 1 ? '' : 's'}`}
                            </div>
                        </div>
                    </div>

                    <Listing
                        total={this.props.posts.totalPages}
                        page={this.props.posts.currentPage}
                        items={this.props.posts.posts} />
                </div>

                <Footer menu={util.try(() => this.props.settings.footer)} />
            </div>
        );

    }
};

module.exports = Search;
