import React from 'react';
import IconClose from '../ui/icon-close.jsx';

class PauseVideo extends React.Component {
  constructor() {
    super();

    this.state = {
        sound: false,
        infoOpen: false,
    };    

    this.handleInfoOpen = this.handleInfoOpen.bind(this)
  }

  componentDidMount() {

    // function isMobile() {
    //   const regex = /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
    //   return regex.test(navigator.userAgent);
    // }

    const buttonsContainer = document.querySelector('.buttons');
    let inactivityTimeout;

    const hideButtons = () => {
      buttonsContainer.classList.add('hidden');
    };

    const showButtons = () => {
      buttonsContainer.classList.remove('hidden');
    };

    const resetInactivityTimer = () => {
      
      showButtons(); // Show buttons when mouse moves
      clearTimeout(inactivityTimeout); // Clear any existing timeout
      if (window.matchMedia("(min-width: 1030px)")) {
        inactivityTimeout = setTimeout(hideButtons, 4000); // Start a new timeout
      }
    };

    // Add event listener for mouse movement
    if (window.matchMedia("(min-width: 1030px)")) {
      document.addEventListener('mousemove', resetInactivityTimer);
      document.addEventListener('touch', resetInactivityTimer);
    }

    // Initialize inactivity timeout
    // resetInactivityTimer();
  }

  handleInfoOpen() {
    this.setState({
      infoOpen: !this.state.infoOpen
    })
  }
  
  render() {
     
    const _vimeoID = this.props.pause.vimeo_link ? this.props.pause.vimeo_link.split('/').pop() : null;



    return (
      <div className="bg-black fixed top-0 left-0 right-0 bottom-0 z4">

        <div className="vimeo-container">
          <iframe               
              allow="autoplay" 
              allowfullscreen="allowfullscreen" frameborder="0"
              src={`https://player.vimeo.com/video/${_vimeoID}?controls=0&autoplay=1&loop=1`} />
        </div>

        <div className="fixed md-show top-0 bottom-0 left-0 right-0 z-10"></div>

        <div 
          className='info-panel col-12 sm-col-7 center-item'
          style={{
            display: this.state.infoOpen ? 'flex' : 'none'
          }}>

 

          <div className="relative panel-inner bg-80-white">
            <button 
              onClick={this.handleInfoOpen} 
              className='close-button absolute z-10 right-0 md-mt5 md-mr5 top-0 button p0 flex'>
              <IconClose />
            </button>            
            <div 
              className="md-pt0 rte" 
              dangerouslySetInnerHTML={{__html: this.props.pause.info}}>
            </div>
          </div>

        </div>

        <div 
          className="buttons clearfix container p3 z4 absolute bottom-0 right-0"
          style={{
            
            transition: 'opacity 0.5s ease-in-out'
          }}>
          <div className="flex justify-end h3">
            <div className="flex">
            <div className="flex justify-end h3 mx1">
              <button 
                onClick={this.props.handleClose} 
                className='caps bg-half-white cursor-pointer border-0'>Patter</button>
            </div>            
            <button 
              onClick={this.handleInfoOpen} 
              className='caps bg-half-white cursor-pointer border-0 flex items-center'>
                <span>PAUSE</span>
                <span className="ml1">
                  {this.state.infoOpen ? 
                    <span>
                      <svg xmlns="http://www.w3.org/2000/svg" width="10.89" height="10.89" viewBox="0 0 10.89 10.89">                        
                        <line id="Line_5" data-name="Line 5" y2="10.89" transform="translate(10.89 5.445) rotate(90)" fill="none" stroke="#000" strokeWidth="1"/>
                      </svg>                      
                    </span> 
                    : 
                    <span>
                      <svg xmlns="http://www.w3.org/2000/svg" width="10.89" height="10.89" viewBox="0 0 10.89 10.89">
                        <line id="Line_4" data-name="Line 4" y2="10.89" transform="translate(5.445)" fill="none" stroke="#000" strokeWidth="1"/>
                        <line id="Line_5" data-name="Line 5" y2="10.89" transform="translate(10.89 5.445) rotate(90)" fill="none" stroke="#000" strokeWidth="1"/>
                      </svg>
                    </span>}
                </span>
            </button>
            </div>
          </div>
        </div>        

      </div>  
    )
  }
}

module.exports = PauseVideo;