/*
 * Listing loading indicator
 */

import React from 'react';


/**
 * Loading
 * @prop {boolean} active
 */
const Loading = (props) => {
    return (
        <div className='clearfix'>
            <span className='h5 inline-block pl2 sm-pl3 pt5'>Loading</span>
            <span>
                <svg width='1rem' height='100%' viewBox='0 0 100 100' preserveAspectRatio='xMidYMid'>
                    <circle cx='84' cy='92' r='0' fill='#000000'>
                        <animate attributeName='r' values='8;0;0;0;0' keyTimes='0;0.25;0.5;0.75;1' keySplines='0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1' calcMode='spline' dur='3.1s' repeatCount='indefinite' begin='0s' />
                        <animate attributeName='cx' values='84;84;84;84;84' keyTimes='0;0.25;0.5;0.75;1' keySplines='0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1' calcMode='spline' dur='3.1s' repeatCount='indefinite' begin='0s' />
                    </circle>
                    <circle cx='16' cy='92' r='5.77178' fill='#000000'>
                        <animate attributeName='r' values='0;8;8;8;0' keyTimes='0;0.25;0.5;0.75;1' keySplines='0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1' calcMode='spline' dur='3.1s' repeatCount='indefinite' begin='-1.55s' />
                        <animate attributeName='cx' values='16;16;50;84;84' keyTimes='0;0.25;0.5;0.75;1' keySplines='0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1' calcMode='spline' dur='3.1s' repeatCount='indefinite' begin='-1.55s' />
                    </circle>
                    <circle cx='84' cy='92' r='4.22822' fill='#000000'>
                        <animate attributeName='r' values='0;8;8;8;0' keyTimes='0;0.25;0.5;0.75;1' keySplines='0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1' calcMode='spline' dur='3.1s' repeatCount='indefinite' begin='-0.775s' />
                        <animate attributeName='cx' values='16;16;50;84;84' keyTimes='0;0.25;0.5;0.75;1' keySplines='0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1' calcMode='spline' dur='3.1s' repeatCount='indefinite' begin='-0.775s' />
                    </circle>
                    <circle cx='69.6241' cy='92' r='8' fill='#000000'>
                        <animate attributeName='r' values='0;8;8;8;0' keyTimes='0;0.25;0.5;0.75;1' keySplines='0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1' calcMode='spline' dur='3.1s' repeatCount='indefinite' begin='0s' />
                        <animate attributeName='cx' values='16;16;50;84;84' keyTimes='0;0.25;0.5;0.75;1' keySplines='0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1' calcMode='spline' dur='3.1s' repeatCount='indefinite' begin='0s' />
                    </circle>
                    <circle cx='35.6241' cy='92' r='8' fill='#000000'>
                        <animate attributeName='r' values='0;0;8;8;8' keyTimes='0;0.25;0.5;0.75;1' keySplines='0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1' calcMode='spline' dur='3.1s' repeatCount='indefinite' begin='0s' />
                        <animate attributeName='cx' values='16;16;16;50;84' keyTimes='0;0.25;0.5;0.75;1' keySplines='0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1' calcMode='spline' dur='3.1s' repeatCount='indefinite' begin='0s' />
                    </circle>
                </svg>
            </span>
        </div>
    );
};

module.exports = Loading;
