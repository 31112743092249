import React, { Fragment } from 'react';
import IconDot from '../ui/icon-dot.jsx';
import Image from '../images/image.jsx';
import Moment from 'moment';
import util from '../../utilities';

class ImagePlacement extends React.Component {

    render() {
    
    const image = this.props.image;
    const portrait = image.height > image.width;

    return (
      <div className={`relative ${portrait && this.props.seed===1 ? 'sm-col-9 col-12' : 'col-12'}`}>
        <div className="sm-px3" style={{marginTop: `${this.props.top || '0'}%`}}>
          <Image image={image} className='col-12' />
        </div>
      </div>    
    )
  }
}


class Features extends React.Component {
  
  randPosition() {
    return Math.ceil(Math.random() * (9 - 1) + 1);
  }

  render() {

    if(!this.props.featured || !Array.isArray(this.props.featured.featured)){
      return <span></span>
    }
    
    const tags = util.try(() => this.props.featured.featured[0].terms.article_category.map((category, i) => (
      <a
          key={i}
          className='blue'
          href={`/search?category=${category.slug}`}>
          {category.slug}
      </a>
  )));    

   return (
      <div className='module-features relative clearfix min-height-100vh hp-top'> 

        <div className="relative">
          
          <div className="absolute top-0 height-100 center col-12">
            <a className="sm-col-10 mx-auto" href={this.props.featured.featured[0].slug}>
              <h1 
                className="sticky sticky-title hp-top z2 top-50 center px4 sm-px0 mb0" 
                dangerouslySetInnerHTML={{__html:this.props.featured.title}} />            
            </a>
          </div>

          <div className='upright top-0 h3 absolute caps gray'>
            <span className=''>
                {Moment(this.props.featured.date).format('MMMM YYYY')}
            </span>
          </div>

          <div className="sm-show">
          <div className={`feature-items flex sm-mln3 sm-mrn3`} style={{minHeight: '700px'}}>
            <div className="col-1"></div>
            
            {this.props.featured.images.map((img, i) => {
              const containerClass = i === 1 ? 'col-4' : 'col-3';
              return (
              <div 
                className={`${containerClass} sm-show`}
                key={i}>
                <div className={i===0 ? '' : ''}>
                  <div className="sm-show">
                    <a href={this.props.featured.featured[0].slug}>
                      <ImagePlacement image={img.image} seed={i} top={img.top_position} /> 
                    </a>                   
                  </div>
                </div>
              </div>
              )
            })}
            <div className="col-1"></div>            
          </div>
          </div>

          <div className="feature-items sm-hide">
            {this.props.featured.images.map((img, i) => {
              return (
              <Fragment                 
                key={i}>                            
                  <div className="sm-hide col-12 flex pl6">                                                             
                      <div className={`${i===0 ? '' : `col-${this.randPosition()}`}`}></div>
                      <div className="col-4">
                        <ImagePlacement seed={i} image={img.image} />   
                      </div>                   
                  </div>
                  {i < this.props.featured.images.length-1 ? <div className="sm-hide col-5 aspect-1-1" /> : <span/>}                
              </Fragment>
              )
            })}
          </div>

        </div>

        <div className="mt7 mb7">
          <div className="center block">
            <IconDot />
          </div>
          <div className="flex py6">
            <div className="col-3 sm-col-4"></div>
            <div className="col-9 sm-col-8">
              <div className='blue caps h3'>
                {tags}
              </div>
              <div className="mt1 landing-standfirst">
                {util.try(() => this.props.featured.featured[0].acf.standfirst)}
              </div>
            </div>
          </div>
          <div className="center block">
            <IconDot />
          </div>
        </div>

      </div>)
  }
}

module.exports = Features;