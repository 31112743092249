import React from 'react';
import Moment from 'moment';
import CategoryLabel from '../../category-label.jsx';
import Image from '../../images/image.jsx';


class ListingItem extends React.Component {

    render() {

        const container = this.props.featuredDesktop
            ? 'col-12 md-col-8'
            : 'col-12 md-col-4';

        const image = this.props.featuredMobile
            ? 'col-12 mb2 md-m0'
            : 'col-5 md-col-12';

        const textWidth = this.props.featuredMobile
            ? 'col-9 md-col-12 mb2 md-m0'
            : 'col-7 md-col-12';

        const textSizeMobile = this.props.featuredMobile
            ? 'h2'
            : 'h4';

        const textSizeDesktop = this.props.featuredDesktop
            ? 'md-h2'
            : 'md-h4';

        return (
            <div className={`col ${container} mt2 md-mt0`}>
                <div className={`hover-sibling col ${image}`}>
                    <div className="px1 sm-px3">
                        <a
                            href={this.props.item.slug}
                            className="block">
                            <Image
                                image={this.props.item.acf.listing_image || this.props.item.acf.lead_image}
                                className="block col-12" />
                        </a>
                    </div>
                </div>
                <div className={`hover-container col-right ${textWidth}`}>
                    <div className="pl1 pr3 sm-px3">
                        <a href={this.props.item.slug}>
                            <h3
                                className={`hover-target-blue mt0 md-mt2 mb0 ${textSizeMobile} ${textSizeDesktop}`}
                                dangerouslySetInnerHTML={{__html: this.props.item.title.rendered || this.props.item.title}}/>
                        </a>
                        <span className="caps h3 block mt1 md-mt2 line-height-2">
                            <span>
                                {Moment(this.props.item.date).format('MMM-YYYY')}
                            </span>
                            {this.props.item.article_category && (
                                <span className='ml1'>
                                    <CategoryLabel categories={this.props.item.article_category} />
                                </span>
                            )}
                        </span>
                        <p className="mb0 h5 mt1 md-mt2">
                            {this.props.item.acf.standfirst}
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}

module.exports = ListingItem;
