import React from 'react';

class CategoryLabel extends React.Component {
    render () {
        return this.props.categories.map((category, i) => {
            return (<a key={i} className='blue' href={`/search?category=${category.slug}`}>{category.slug}</a>);
        });
    }
}

module.exports = CategoryLabel;
