/*
 * Listing container
 */

import React from 'react';
import util from '../../../utilities';


/**
 * Class representing a listing container
 * @extends React.component
 * @prop {object} filters
 * @prop {array} items
 * @prop {number} page
 */
class ListingContainer extends React.Component {

    /**
     * Setup
     */
    constructor(props) {
        super(props);
        this.handlePage = this.handlePage.bind(this);
        this.state = {
            filters: props.filters || {},
            items: props.items || [],
            page: props.page || props.currentPage || 1
        };
    }

    /**
     * Setup
     */
    componentDidMount() {
        this.setState({
            pushStateSupport: !!(window.history && history.pushState)
        });
    }

    /**
     * Navigate to a new page
     * @param {number} page
     * @param {function} callback
     */
    handlePage(page, callback) {
        this.setState({
            page: page
        }, () => {
            if (this.refs.listing) {
                util.scrollTo(200);
            } else if (window.scroll) {
                window.scroll(0,0);
            }
            if (this.state.pushStateSupport) {
                util.updateHistoryState('page', page);
            }
            if (typeof callback === 'function') {
                callback();
            }
        });
    }

}

module.exports = ListingContainer;
